import React from "react"
import { PageH3Header } from "../typography/page-headers"
import { SectionSubHeader } from "../typography/section-headers"
import {
  CalendarIcon,
  MapIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline"

const features = [
  {
    name: "Meet on Monday Nights",
    description:
      "BSA Scout Troop 146 meets at First Presbyterian Church every Monday night (unless directed otherwise) from 7:00 - 8:30 PM.",
    imageIndex: "map",
  },
  {
    name: "A Proven Program",
    description:
      "The BSA program has a long history of providing youth exposure to new skills, exciting opportunities to explore nature, and a sense of ownership of thier growth.",
    imageIndex: "book",
  },
  {
    name: "Monthly Outdoor Activities",
    description:
      "From weekend camping trips, nature hikes, to community service projects, we get youth out into nature and teach them how to conduct themselves in a safe and responsible manner.",
    imageIndex: "calendar",
  },
]

export default function FeatureSection() {
  return (
    <div className="bg-light-blue">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-row flex-wrap gap-8 py-20">
          {features.map((feature, fIndex) => (
            <div className="md:flex-same-33 flex flex-col" key={fIndex}>
              <div className="flex flex-col grow p-4 md:p-0 md:pr-4 md:border-r-4 border-split-blue border-opacity-30">
                <div className="flex flex-row justify-center p-6 text-split-blue">
                  <ReturnIcon hint={feature.imageIndex} />
                </div>
                <SectionSubHeader
                  text={feature.name}
                  tint={"dark"}
                  direction={"center"}
                />
                <p className="m-4 md:ml-0 p-6 md:pb-12 md:pl-0 text-left text-xl ">
                  {feature.description}
                </p>
                <p className="grow border-b-4 border-split-blue border-opacity-30" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function ReturnIcon({ hint }) {
  console.log(hint)
  switch (hint) {
    case "map":
      return <MapIcon className="h-16 w-16" />
      break
    case "book":
      return <BookOpenIcon className="h-16 w-16" />

    default:
      return <CalendarIcon className="h-16 w-16" />
      break
  }
}
