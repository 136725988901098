import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PageH1Header, PageH2Header } from "../typography/page-headers"

export default function HeroSection() {
  return (
    <div className="relative bg-coffee z-20 overflow-hidden">
      <div className="relative max-w-4xl mx-auto py-32 px-8 z-30">
        <PageH1Header text="Find Your Next Adventure!" tint={"light"} />
        <div className="md:w-2/3 md:mx-auto">
          <p className="text-off-white font-bold text-xl">
            For boys and girls from 11 to 17, BSA Pack 146 in Pottstown, PA has
            a year-long program that combines outdoor activities, trusted
            skill-building curriculum, and a practice of serving the local
            community.
          </p>
          <p className="text-off-white font-bold text-xl">
            At BSA Troop 146, boys learn skills and make friends for life.
          </p>
        </div>
      </div>
      <div className="absolute inset-0 opacity-60 z-10">
        <StaticImage
          placeholder="dominantColor"
          src="../../images/bsa-slider.png"
          alt="Collage of different projects"
          layout="fixed"
          objectFit="cover"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        />
      </div>
    </div>
  )
}
